import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRb from "../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../App";
import { readGroups, readGroupStores } from "../api/GroupApi";
import DatePicker from "react-datepicker";
import { debounce } from "../fp";
import { getCoupons } from "../api/widgetsApi";
import {
  addDays,
  formatDate,
  print,
  handleCsv,
  isValid,
  pad,
  formatMoney
} from "../utils";

const Coupons = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -2));
  const [endDate, setEndDate] = useState(new Date());
  const [stores, setStores] = useState([]);
  const [group, setGroup] = useState("");
  const [storelist, setStorelist] = useState("");
  const [optionsSelected, setOptionsSelected] = useState(false);
  const [groups, setGroups] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  useEffect(() => {
    loadGroupsForPicker();
  }, []);

  useEffect(() => {
    readStoresInGroup();
  }, [group]);

  useEffect(() => {
    if (stores && stores.length > 0) {
      const storelist = stores
        .map((s) => pad(s.store_Number, 3, "0"))
        .join(",");
      setStorelist(storelist);
    }
  }, [stores]);

  const readStoresInGroup = () => {
    if (!group) return;
    readGroupStores(context.url, group, context.token)
      .then((resp) => {
        const j = resp.data;
        if (j.error == "0") {
          setStores(j.items);
        } else {
          toast.warn(j.msg);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (storelist.length > 0) {
      getCouponData();
    }
  }, [storelist, startDate, endDate]);

  useEffect(() => {
    if (data.length > 0) {
      const debounceHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
        if (dimensions !== null) {
          findDivHeight();
        }
      }, 500);

      findDivHeight();

      window.addEventListener("resize", debounceHandleResize);

      return () => {
        window.removeEventListener("resize", debounceHandleResize);
      };
    }
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("sm-body");
    // body.style.height = remaining + "px";
    body.style.overflow = "hidden";

    const recList = document.getElementById("coupons");
    if (recList) {
      recList.style.height = remaining + "px";
      recList.style.overflowY = "scroll";
    }
  };

  const getCouponData = () => {
    setIsLoading(true);
    getCoupons(
      context.basketUrl,
      formatDate(startDate),
      formatDate(endDate),
      storelist
    )
      .then((resp) => {
        setIsLoading(false);
        const j = resp.data;
        if (j.error === 0) {
          setData(j.coupons);
        } else {
          toast.error(j.msg);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const loadGroupsForPicker = () => {
    if (!isValid(context)) return;
    if (!isValid(context.token)) return;
    setIsLoading(true);
    readGroups(
      context.url,
      context.token,
      (response) => {
        setIsLoading(false);
        const j = response.data;
        if (j.error === 0) {
          setGroups(j.groups);
        } else {
          toast.error("loadGroupsForPicker: " + j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log(err);
        toast.error(
          "loadGroupsForPicker: An error occured processing your request",
          {
            position: toast.POSITION.TOP_LEFT
          }
        );
      }
    );
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
  };

  const exportCsv = (filename) => {
    const div = document.getElementById("rec-contents");
    if (div) {
      let isVisible = div.checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true
      });
      if (isVisible) {
        handleCsv("testdata", "coupons.csv");
      } else {
        handleCsv(data, filename);
      }
    } else {
      handleCsv(data, filename);
    }
  };

  const handlePrint = () => {
    // check to see if we are viewing the list of the contents
    if (optionsSelected) {
      print(document.getElementById("rec-contents").innerHTML);
    } else {
      print(document.getElementById("rec-list").innerHTML);
    }
  };

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    context.lastSearchType = 2;
    context.lastGroup = e.target.value;
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb></HeaderRb>
      <div id="r-controls">
        <h3 style={{ margin: "auto", width: "90%" }}>Coupons</h3>
        <form className="form-inline" style={{ margin: "auto" }}>
          <div className="form-group row w-100">
            <div className="col-xl-3 col-lg-4 col-sm-6 align-items-center justify-content-center d-flex mt-1">
              <div>
                <span style={{ marginRight: "10px" }}>Group:</span>
                <select
                  id="comboGroup"
                  className="form-control"
                  onChange={handleGroupChange}
                  value={context.lastGroup}
                >
                  {groups.length === 0
                    ? null
                    : groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.group_name}
                        </option>
                      ))}
                </select>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </form>
        <div className="d-report-spacer">&nbsp;</div>
      </div>
      <div className="container-fluid" id="coupons">
        <div id="sm-controls">
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => exportCsv("receivers.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>
        </div>
        <div id="sm-body" style={{ position: "relative" }}>
          {data && data.length > 0 ? <div>{data.length} records</div> : null}
          <React.Fragment>
            {data.length === 0 ? (
              <div className="d-empty-report">
                There were no records returned
              </div>
            ) : (
              <table style={{ width: "90%" }}>
                <thead>
                  <tr>
                    <th>Store</th>
                    <th>Term</th>
                    <th>Trans</th>
                    <th>Description</th>
                    <th>Store Cpn Amount</th>
                    <th>Vendor Cpn Amount</th>
                    <th>Date</th>
                    <th>Cashier Seq</th>
                    <th>Cashier #</th>
                    <th>Cashier </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((c, index) => (
                    <tr key={`cpn-${index}`}>
                      <td>{c.f1056}</td>
                      <td>{c.f1057}</td>
                      <td>{c.f1032}</td>
                      <td>{c.f1041}</td>
                      <td>{formatMoney(c.storeCpnAmount)}</td>
                      <td>{formatMoney(c.vendorCpnAmount)}</td>
                      <td>{formatDate(c.f1505)}</td>
                      <td>{c.f1185}</td>
                      <td>{c.f1126}</td>
                      <td>{c.f1127}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </React.Fragment>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Coupons;
