import React, { useState, useEffect, useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import HeaderRb from "../../common/HeaderRb";
import { toast } from "react-toastify";
import { ContextConfig } from "../../App";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { savePrefs } from "../../api/GlobalApi";
import {
  usePrevious,
  isValidDate,
  addDays,
  formatDate,
  print,
  handleCsv,
  truncateString,
  isValid,
  formatMoney
} from "../../utils";
import { getAssignedStoresAsync } from "../../api/StoreApi";
import { debounce } from "../../fp";
import backButton from "../../assets/img/back-button-svgrepo-com.svg";
import {
  getOrders,
  getRecStoreList,
  getOrderItems
} from "../../api/ReportsApi";

const PerReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [invData, setInvData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [stores, setStores] = useState([]);
  const [storeid, setStoreid] = useState("");
  const [optionsSelected, setOptionsSelected] = useState(false);
  const [trans, setTrans] = useState({});
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const context = useContext(ContextConfig);

  const previousData = usePrevious(data);

  useEffect(() => {
    if (!isValid(storeid) || storeid.length === 0) {
      setStoreid(context.lastStoreid);
    }

    if (isValid(context.startDate)) {
      setStartDate(context.startDate);
    }

    if (isValid(context.endDate)) {
      setEndDate(context.endDate);
    }
    fetchStores();
  }, []);

  useEffect(() => {
    handleResetOptions();
  }, [storeid]);

  useEffect(() => {
    if (optionsSelected) {
      loadInv();
    }
  }, [optionsSelected]);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      loadData();
    }

    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if (dimensions !== null) {
        findDivHeight();
      }
    }, 500);

    findDivHeight();

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  }, [data]);

  const findDivHeight = () => {
    const navbar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;

    const controls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = navbar + controls + 30;
    const remaining = window.innerHeight - usedSpace;

    const body = document.getElementById("sm-body");
    body.style.height = remaining + "px";
    body.style.overflow = "hidden";

    const recList = document.getElementById("rec-list");
    if (recList) {
      recList.style.height = remaining + "px";
      recList.style.overflowY = "scroll";
    }
  };

  const fetchStores = () => {
    getAssignedStoresAsync(context.url, context.token).then((response) => {
      const j = response.data;
      if (j.error === 0) {
        loadStorelist(j.items);
        //setStores(j.items);
      } else {
        toast.error(j.msg, {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const loadStorelist = (existingStores) => {
    getRecStoreList(context.basketUrl, context.basketApiKey)
      .then((resp) => {
        const j = resp.data;
        if (j.error == "0") {
          const serverStores = j.items;
          const mergedStoreList = mergeStores(existingStores, serverStores);
          setStores(mergedStoreList);
        } else {
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setData([]);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const mergeStores = (l1, l2) => {
    const newArray = [];
    l1.forEach((store) => {
      if (l2.includes(store.store_Number.toString().padStart(3, "0"))) {
        newArray.push(store);
      }
    });

    return newArray;
  };

  const loadData = () => {
    if (!isValidDate(context.startDate)) {
      context.startDate = addDays(new Date(), -7);
    }
    getOrders(
      context.basketUrl,
      context.basketApiKey,
      formatDate(context.startDate),
      formatDate(context.endDate),
      context.lastStoreid,
      "PER"
    )
      .then((resp) => {
        // setIsLoading(false);
        const j = resp.data;
        if (j.error == "0") {
          setData(j.orders);
        } else {
          setData([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        setData([]);
        console.log(err);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handleResetOptions = () => {
    setOptionsSelected(false);
    loadData();

    const div = document.getElementById("rec-contents");

    if (div) {
      let isVisible = div.checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true
      });
      if (isVisible) {
        div.classList.toggle("slidin");
        div.classList.toggle("slidout");
      }
    }
  };

  const handleSelectionChange = (e) => {
    context.lastStoreid = e.target.value;
    setStoreid(e.target.value);

    savePrefs(
      context.url,
      context.token,
      context.lastStoreid,
      context.lastGroup,
      context.lastSearchType,
      context.lastGroupName
    );
  };

  const handleStartDateChange = (e) => {
    context.startDate = e;
    setStartDate(context.startDate);
    handleResetOptions();
  };

  const handleEndDateChange = (e) => {
    context.endDate = e;
    setEndDate(context.endDate);
    handleResetOptions();
  };

  const exportCsv = (filename) => {
    const div = document.getElementById("rec-contents");
    if (div) {
      let isVisible = div.checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true
      });
      if (isVisible) {
        handleCsv(
          invData,
          "rec-" +
            invData.storeid +
            "-" +
            invData.orderid +
            "-" +
            invData.orderdate +
            ".csv"
        );
      } else {
        handleCsv(data, filename);
      }
    } else {
      handleCsv(data, filename);
    }
  };

  const loadInv = () => {
    setIsLoading(true);
    getOrderItems(
      context.basketUrl,
      context.basketApiKey,
      context.lastStoreid,
      trans.id
    )
      .then((resp) => {
        setIsLoading(false);
        const j = resp.data;
        if (j.error == "0") {
          setInvData(j.items);
        } else {
          setInvData([]);
          toast.error(j.msg, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setInvData([]);
        toast.error("An error occured processing your request", {
          position: toast.POSITION.TOP_LEFT
        });
      });
  };

  const handlePrint = () => {
    // check to see if we are viewing the list of the contents
    if (optionsSelected) {
      print(document.getElementById("rec-contents").innerHTML);
    } else {
      print(document.getElementById("rec-list").innerHTML);
    }
  };

  const handleOptionsClick = (options) => {
    setTrans(options);
    setOptionsSelected(true);
    const contents = document.getElementById("rec-contents");
    // here is where we are handling the animating of the report details
    contents.classList.toggle("slidin");
    contents.classList.toggle("slidout");

    const masterNavBar = document
      .getElementById("master-navbar")
      .getBoundingClientRect().height;
    const rControls = document
      .getElementById("r-controls")
      .getBoundingClientRect().height;
    const usedSpace = masterNavBar + rControls; // + goBack + recInfo;
    const remaining = window.innerHeight - usedSpace;
    contents.style.height = remaining - 50 + "px";
    contents.style.overflowY = "scroll";
  };

  const getTotalActivePrice = () => {
    return invData.reduce((acc, cur) => {
      return (
        parseFloat(acc) +
        parseFloat(cur.active_price) / parseFloat(cur.active_qty)
      );
    }, 0);
  };

  const getTotalCost = () => {
    return invData.reduce((acc, cur) => {
      return acc + parseFloat(cur.basecost) / parseFloat(cur.basecase);
    }, 0);
  };

  const getTotalQty = () => {
    return invData.reduce((acc, cur) => {
      return acc + parseInt(cur.qty);
    }, 0);
  };

  const getTotalActiveRetail = () => {
    return invData.reduce((acc, cur) => {
      return (
        acc +
        (parseFloat(cur.active_price) / parseFloat(cur.active_qty)) *
          parseFloat(cur.qty)
      );
    }, 0);
  };

  const getTotalExtendedCost = () => {
    return invData.reduce((acc, cur) => {
      return (
        acc +
        (parseFloat(cur.basecost) / parseFloat(cur.basecase)) *
          parseFloat(cur.qty)
      );
    }, 0);
  };

  const getTotalProfit = () => {
    return invData.reduce((acc, cur) => {
      return (
        acc +
        (parseFloat(cur.active_price) / parseFloat(cur.active_qty)) *
          parseFloat(cur.qty) -
        (parseFloat(cur.basecost) / parseFloat(cur.basecase)) *
          parseFloat(cur.qty)
      );
    }, 0);
  };

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading Data...">
      <HeaderRb></HeaderRb>
      <div id="r-controls">
        <h3 style={{ margin: "auto", width: "90%" }}>Reports</h3>
        <form className="form-inline" style={{ margin: "auto" }}>
          <div className="form-group row w-100">
            <div className="col-xs-12 col-sm-6 col-lg-3 m-auto">
              Selection Type:{" "}
              <select
                className="form-control"
                id="combodSelectionType"
                value={storeid}
                onChange={handleSelectionChange}
              >
                {stores.map((record, index) => (
                  <option key={index} value={record.storeid}>
                    {truncateString(record.store_Name, 40)}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              Start:&nbsp;
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-3 mt-1">
              End:&nbsp;
              <DatePicker selected={endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </form>
        <div className="d-report-spacer">&nbsp;</div>
      </div>
      <div className="container-fluid" id="receivers">
        <div id="sm-controls">
          <div className="export-report">
            <span className="print-report" onClick={handlePrint}>
              <i className="far fa-print"></i>
            </span>
            <span
              className="csv-report"
              onClick={() => exportCsv("receivers.csv")}
            >
              <i className="far fa-save"></i>
            </span>
          </div>
        </div>
        <div id="sm-body" style={{ position: "relative" }}>
          <React.Fragment>
            {data.length === 0 ? (
              <div className="d-empty-report">
                There were no records returned
              </div>
            ) : (
              <React.Fragment>
                <div id="rec-contents" className="rec-overlay slidout">
                  <div
                    id="go-back"
                    style={{ margin: "auto", width: "90%" }}
                    onClick={() => handleResetOptions()}
                  >
                    <img
                      alt="back"
                      style={{ width: "50px", cursor: "pointer" }}
                      src={backButton}
                    />
                    <span style={{ marginLeft: "10px" }}>Go Back</span>
                  </div>
                  <div
                    id="rec-info"
                    style={{
                      width: "90%",
                      margin: "auto",
                      marginBottom: "10px",
                      fontSize: "1.2rem",
                      color: "#fff"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>Store: {trans.storenumber}</div>
                      <div>Id: {trans.id}</div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>Order Date: {formatDate(trans.orderdate)}</div>
                      <div>Status: {trans.status} </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>RecordCount: {trans.recordcount}</div>
                      <div>Order Type: {trans.ordertype}</div>
                    </div>
                  </div>
                  <div id="main-table">
                    <table
                      style={{ width: "90%", margin: "auto" }}
                      className="rec-table"
                    >
                      <thead id="rec-thead">
                        <tr>
                          <th
                            className="rec-table-center"
                            style={{ textAlign: "left" }}
                          >
                            Line
                          </th>
                          <th style={{ textAlign: "left" }}>UPC</th>
                          <th
                            style={{ textAlign: "left" }}
                            className="rec-table-center"
                          >
                            Item
                          </th>

                          <th
                            style={{ textAlign: "right" }}
                            className="rec-table-center"
                          >
                            Active
                          </th>
                          <th
                            style={{ textAlign: "right" }}
                            className="rec-table-center"
                          >
                            Cost
                          </th>
                          <th
                            style={{ textAlign: "center" }}
                            className="rec-table-right"
                          >
                            Qty
                          </th>
                          <th
                            style={{ textAlign: "right" }}
                            className="rec-table-right"
                          >
                            eRet
                          </th>
                          <th
                            style={{ textAlign: "right" }}
                            className="rec-table-right"
                          >
                            eCost
                          </th>
                          <th
                            style={{ textAlign: "right" }}
                            className="rec-table-right"
                          >
                            Profit
                          </th>
                          <th
                            style={{ textAlign: "center" }}
                            className="rec-table-center"
                          >
                            Margin
                          </th>
                          <th
                            style={{ textAlign: "center" }}
                            className="rec-table-center"
                          >
                            Weight
                          </th>
                          <th
                            style={{ textAlign: "center" }}
                            className="rec-table-center"
                          >
                            Vendor
                          </th>
                        </tr>
                      </thead>
                      <tbody id="rec-list-body">
                        {invData.map((record, index) => {
                          const activePrice =
                            record.active_price / record.active_qty;
                          const cost = record.basecost / record.basecase;
                          return (
                            <tr key={index}>
                              <td
                                className="rec-table-center"
                                style={{ textAlign: "left" }}
                              >
                                {record.linenumber}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {record.upc}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {record.description}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {activePrice.toFixed(2)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {cost.toFixed(3)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {record.qty}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(activePrice * record.qty).toFixed(2)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(cost * record.qty).toFixed(2)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {(
                                  activePrice * record.qty -
                                  cost * record.qty
                                ).toFixed(2)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {(
                                  ((activePrice - cost) / activePrice) *
                                  100
                                ).toFixed(2)}
                                %
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {record.weight}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {record.vendorid}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="12">
                            <hr style={{ color: "white" }} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3"></td>

                          <td style={{ textAlign: "right" }}>
                            {formatMoney(getTotalActivePrice())}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {formatMoney(getTotalCost())}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {getTotalQty()}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {formatMoney(getTotalActiveRetail())}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {formatMoney(getTotalExtendedCost())}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {formatMoney(getTotalProfit())}
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "center" }}></td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div style="min-height:50px">&nbsp;</div> */}
                  </div>
                </div>

                <div id="rec-list">
                  <table style={{ width: "90%", margin: "auto" }}>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Store</th>
                        <th>ID</th>
                        <th style={{ textAlign: "center" }}>Record Count</th>
                        <th>Status</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((record, index) => (
                        <tr
                          key={index}
                          className="rec-row"
                          onClick={() => handleOptionsClick(record)}
                        >
                          <td>{record.orderdate}</td>
                          <td>{record.storenumber}</td>
                          <td>{record.id}</td>
                          <td style={{ textAlign: "center" }}>
                            {record.recordcount}
                          </td>
                          <td>{record.status}</td>
                          <td>{record.ordertype}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PerReport;
