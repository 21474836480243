import CryptoJS from "crypto-js";
import { useRef, useEffect } from "react";

const isValid = (val) => {
  if (typeof val === "undefined" || val === null) {
    return false;
  } else {
    if (typeof val === "string" && val.length === 0) {
      return false;
    }

    if (typeof val === "object") {
      if (Object.keys(val).length === 0) {
        return false;
      }
    }
    return true;
  }
};

const isValidDate = (val) => {
  if (typeof val === "undefined" || val === null) {
    return false;
  } else {
    if (typeof val === "string" && val.length === 0) {
      return false;
    }
    return true;
  }
};

const formatDate = (date) => {
  if (typeof date === "undefined" || date === null) return "";
  if (date.length === 0) return "";

  if (typeof date === "string") {
    date = new Date(date);
  }
  const month = date.getMonth() + 1,
    day = date.getDate(),
    year = date.getFullYear();

  return month + "/" + day + "/" + year;
};

const formatTimeString = (date) => {
  // returns a long time string
  // 17:00:00 GMT-0500 (Central Daylight Time)
  if (typeof date === "undefined" || date === null) return "";
  if (date.length === 0) return "";

  if (typeof date === "string") {
    date = new Date(date);
  }

  return date.toTimeString();
};

const formatTimeShort = (date) => {
  if (typeof date === "undefined" || date === null) return "";
  if (date.length === 0) return "";

  if (typeof date === "string") {
    date = new Date(date);
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const formatTimeAmPm = (date) => {
  if (typeof date === "undefined" || date === null) return "";
  if (date.length === 0) return "";

  if (typeof date === "string") {
    date = new Date(date);
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour 0 should be 12
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const formatTimeMilitary = (date) => {
  if (typeof date === "undefined" || date === null) return "";
  if (date.length === 0) return "";

  if (typeof date === "string") {
    date = new Date(date);
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + minutes;
};

const addDays = (date, days) => {
  if (typeof date === "undefined" || date === null) {
    date = new Date();
  }
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const getCurrentState = (
  context,
  setShowGroups,
  setShowSelection
  // setStartDate,
  // setEndDate
) => {
  if (context.lastSearchType == 2) {
    if (context.lastGroup === null || context.lastGroup === 0) {
      if (isValid(setShowGroups)) {
        setShowGroups(true);
        return;
      }
    }
  } else if (context.lastStoreid === null || context.lastStoreid === 0) {
    if (isValid(setShowSelection)) {
      setShowSelection(true);
    }
  }
  // if (!isValid(context.endDate)) {
  //   setEndDate(new Date());
  //   //setStartDate(addDays(context.endDate, -7));
  // }
};

const usePrevious = (value) => {
  if (typeof value === "undefined" || value === null) return "";
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // remember the last callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  //set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, delay);
};

const ConsoleLog = ({ children }) => {
  console.log(children);
  return false;
};

const convertAmount = (amount) => {
  if (!isValid(amount)) return "";
  if (amount.toString().length === 0) return "";

  amount = parseFloat(amount);
  try {
    let twoDigits = amount.toFixed(2);
    return twoDigits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    console.log(e);
    return "";
  }
};

const setDecimal = (amount) => {
  if (!isValid(amount)) return "";

  if (amount.toString().length === 0) return "";

  try {
    let places = Math.round(amount * 100) / 100;
    return places.toString();
  } catch (e) {
    console.log(e);
    return "";
  }
};

const userLevels = [
  { id: 0, name: "No POS" },
  { id: 2, name: "Cashier" },
  { id: 3, name: "Clerk" },
  { id: 4, name: "Assistant" },
  { id: 5, name: "Manager" }
];

let lastId = 0;
const useUniqueId = (prefix = "") => {
  lastId++;
  return [prefix, lastId].join("-");
};

const encrypt = (input, secret) => {
  const key = CryptoJS.enc.Utf8.parse(secret);
  const iv1 = CryptoJS.enc.Utf8.parse(secret);
  const encrypted = CryptoJS.AES.encrypt(input, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted + "";
};

const decrypt = (input, secret) => {
  const key = CryptoJS.enc.Utf8.parse(secret);
  const iv1 = CryptoJS.enc.Utf8.parse(secret);
  const plainText = CryptoJS.AES.decrypt(input, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });

  return plainText.toString(CryptoJS.enc.Utf8);
};

const truncateString = (input, length) => {
  if (typeof input == "string") {
    if (input.length > length) {
      return input.substring(0, length);
    } else {
      return input;
    }
  } else {
    return input;
  }
};

const print = (html) => {
  var win = window.open();
  win.document.write(html);
  win.print();
};

const csv = (headers, data, filename) => {
  try {
    let output = headers + "\r\n" + data;

    var uriContent =
      "data:application/octec-stream," + encodeURIComponent(output);
    save(uriContent, filename);
  } catch (e) {
    return e.msg;
  }
};

const handleCsv = (data, filename) => {
  let headers = "";
  let body = "";
  for (var property in data[0]) {
    headers += property + ",";
  }

  headers = headers.substring(0, headers.length - 1);

  data.map((record) => {
    let line = "";
    for (var prop in record) {
      line += record[prop] + ",";
    }
    body += line.substring(0, line.length - 1) + "\r\n";
  });

  csv(headers, body, filename);
};

const save = (uri, filename) => {
  var link = document.createElement("a");
  if (typeof link.download == "string") {
    document.body.appendChild(link);
    link.download = filename;
    link.href = uri;
    link.click();
  }
};

const removeSpaces = (input) => {
  return input.split(" ").join("_");
};

const getTextWidth = (input) => {
  debugger;
  const fontSize = 12;
  const div = document.createElement("div");
  div.setAttribute("id", "textwidth");
  div.style("display", "none");

  var test = document.getElementById("textwidth");
  test.style.fontSize = fontSize;
  test.innerHTML = input;
  var height = test.clientHeight + 1 + "px";
  var width = test.clientWidth + 1 + "px";
  return {
    height,
    width
  };
};

const getDayOfWeek = (d) => {
  let myDate = new Date(d);
  let dayOfWeek = myDate.getDay();
  switch (dayOfWeek) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
  }
};

const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    let result =
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "");
    return result;
  } catch (e) {
    console.log(e);
  }
};

const formatMoneyWithoutDollars = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    debugger;
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

const pad = (input, desiredLength, padChar, direction = "left") => {
  if (typeof input != "string") return input;
  if (typeof desiredLength != "number") return input;
  if (typeof padChar != "string") return input;

  const charsToPad = desiredLength - input.length;
  // eslint-disable-next-line
  const padding = [...Array(Number(charsToPad))].map((c, i) => {
    return padChar;
  });

  if (direction.toLowerCase() === "left") {
    return padding.join("") + input;
  } else {
    return input + padding.join("");
  }
};

const stringToHTML = (str) => {
  debugger;
  var dom = document.createElement("div");
  dom.innerHTML = str;
  return dom;
};

const handleAjax = (response, dispatch, store) => {
  // store is optional. Can be used when multiple ajax calls exist in one component
  // to determine which piece of state needs to hold the returned data
  const j = response.data;
  if (j.error === 0) {
    dispatch({ type: "success", data: j, store });
  } else {
    dispatch({ type: "error", error: j.msg, store });
  }
};

const Box = (x) => ({
  map: (f) => Box(f(x)),
  inspect: `Box:${JSON.stringify(x)}`,
  fold: (f) => f(x),
  chain: (f) => f(x)
});

export {
  Box,
  isValid,
  encrypt,
  decrypt,
  formatDate,
  addDays,
  getCurrentState,
  usePrevious,
  useInterval,
  ConsoleLog,
  userLevels,
  convertAmount,
  setDecimal,
  useUniqueId,
  truncateString,
  print,
  save,
  handleCsv,
  removeSpaces,
  getTextWidth,
  getDayOfWeek,
  isValidDate,
  formatTimeString,
  formatTimeAmPm,
  formatMoney,
  formatMoneyWithoutDollars,
  pad,
  stringToHTML,
  formatTimeShort,
  handleAjax,
  formatTimeMilitary
};
